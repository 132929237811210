import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Page = () => {
  return (
    <Layout>
      <section className="md:pb-30 pt-12 pb-20 md:pt-16">
        <div className="container">
          <StyledContent>
            <h1>REAL SYNCH Terms and Conditions of Us</h1>
            <p>
              This Real Synch Subscription Agreement (“Agreement”) governs the
              use of the Real Synch online data application and integration
              platform made available by ReWatt, Inc. d/b/a Real Synch (“Real
              Synch,” “we,” “us,” or “our”) on a software-as-a-service basis
              (“SaaS Service”). By accessing and using the SaaS Service, you
              agree to be bound by this Agreement. If you do not agree to the
              terms and conditions of this Agreement you must not use the SaaS
              Service. As used in this Agreement, “you” and “your” mean you
              individually or the entity that you represent. If you are entering
              into this Agreement for an entity, you represent that you have the
              authority to bind that entity. This Agreement takes effect when
              you click “I accept” or a similar button or check box presented to
              you as part of the registration process or when you first use the
              SaaS Service, whichever is earlier, and will remain in effect
              until the end of the relevant subscription term option selected by
              you as part of the registration and ordering process
              (“Subscription Term”) or until terminated as specified in this
              Agreement (“Term”).
            </p>

            <div>
              <h3>GENERAL TERMS AND CONDITIONS</h3>
            </div>

            <div>
              <strong>1. SAAS SERVICE.</strong>

              <ul>
                <li>
                  1.1. 
                  <strong>Access to and Use of the SaaS Service.</strong>
                   Subject to your compliance with the terms and conditions of
                  this Agreement, We grant you a nonexclusive, nontransferable,
                  revocable, limited license during the Term to access and use
                  the SaaS Service solely for your internal business purposes in
                  accordance with this Agreement and any online user
                  documentation that we make available for the SaaS Service
                  (“Documentation”).
                </li>
                <li>
                  1.2. <strong>Licensed Units.</strong> As part of the
                  registration and order process for the SaaS Service, you will
                  be required to select a SaaS Service plan (“Plan”), which will
                  specify limits on the number of users, API calls,
                  synchronizations/integrations between source and target
                  applications (“Synchs”), or other applicable usage metrics
                  (“Licensed Units”). Use of the SaaS Service is limited to the
                  Licensed Units associated with the Plan that you select.
                  Additional Licensed Units may be added during the Subscription
                  Term at then-current prices, prorated for the remainder of the
                  Subscription Term in effect at the time the additional
                  Licensed Units are added, and the added Licensed Units shall
                  terminate on the same date as the pre-existing Licensed Units.
                </li>
                <li>
                  1.3. <strong>Restrictions.</strong> You shall not, and shall
                  not permit any third party to: (i) use the SaaS Service except
                  as expressly authorized under this Agreement; (ii) interfere
                  with or disrupt the integrity or performance of the SaaS
                  Service; (iii) resell, sublicense, time-share, or otherwise
                  use or share the SaaS Service with or for the benefit of any
                  third party; (iv) remove any title, trademark, copyright, or
                  restricted rights notices or labels from the SaaS Service; (v)
                  modify or create a derivative work of the SaaS Service or any
                  portion of the SaaS Service; (vi) reverse engineer,
                  disassemble, decompile, decode, adapt, or otherwise attempt to
                  derive or gain access to the source code, object code or
                  underlying structure or algorithms of the SaaS Service; or
                  (vii) distribute, publish, send, or facilitate unsolicited
                  mass e-mailings, promotions, advertising, or solicitations
                  (e.g. “spam”). You will not use, or encourage, promote,
                  facilitate or instruct others to use, the SaaS Service for any
                  illegal, harmful or offensive use, or transmit, store,
                  display, distribute or otherwise make available content that
                  is illegal, harmful, or offensive. You must abide by all
                  applicable local, state, national and foreign laws, treaties,
                  and regulations in connection with your use of the SaaS
                  Service, including those related to data privacy.
                </li>
                <li>
                  1.4. <strong>Support.</strong> We will provide technical
                  support for the SaaS Service to your authorized contact person
                  consisting of telephone help desk or online support
                  Monday-Friday from 7 a.m. – 7 p.m. CST/CDT, excluding our
                  locally observed holidays.
                </li>
              </ul>

              <strong>2. REGISTRATION, ORDERS, FEES, AND PAYMENT TERMS.</strong>

              <ul>
                <li>
                  2.1. <strong>Registration and User Account.</strong> To access
                  and use the SaaS Service you will be required to register and
                  set up a user account. You must keep your registration
                  information accurate and complete during the Term and protect
                  as confidential any user names, passwords, authentication
                  keys, or security credentials that enable your access to and
                  management of the SaaS Service. You are responsible for all
                  activity occurring under such user account and will notify us
                  immediately of any unauthorized use of your user account or
                  any other known or suspected breach of security. You are
                  responsible for obtaining, maintaining, and supporting all
                  internet access, computer hardware, and other equipment and
                  services needed for access to the SaaS Service.
                </li>
                <li>
                  2.2. <strong>Orders.</strong> You may submit orders for the
                  SaaS Service using our online ordering process (“Order(s)”).
                  All Orders are subject to the terms of this Agreement and are
                  not binding until accepted by us. We may accept or reject
                  Orders in our sole discretion. Your Order will be deemed
                  accepted by us when we provide you with an authentication key
                  to the email address associated with your user account. We are
                  not required to provide the SaaS Service to you until you
                  provide us with all information that we require for processing
                  your Order and provisioning the SaaS Service for you.
                </li>
                <li>
                  2.3. <strong>Fees and Payment Terms.</strong> You will pay
                  subscription fees for the SaaS Service associated with the
                  Plan selected by you in connection with your Order
                  (“Subscription Fees”). Subscription Fees will be billed
                  automatically at the beginning of each applicable Subscription
                  Term. Invoices will be sent to the email address associated
                  with your user account, and all Subscription Fees are due and
                  payable on or before thirty (30) days from the date of
                  invoice. All Subscription Fees will be charged via credit
                  card, and payment of the Subscription Fees is subject to the
                  payment terms of our third party credit card payment
                  processor. Delinquent invoices are subject to late payment
                  fees equivalent to 1.5% of the overdue balance per month (or
                  the maximum permitted by law, whichever is lower), plus any
                  expenses associated with collections. Payments must be made in
                  US dollars. Fees are exclusive of all taxes, levies, and
                  duties imposed by taxing authorities, and you are responsible
                  for all such taxes, excluding taxes based solely on Real
                  Synch’s income. Any billing disputes must be reported to Real
                  Synch in writing within thirty (30) days of the date of the
                  invoice. Except as otherwise expressly provided in this
                  Agreement, all fees are guaranteed and nonrefundable.
                </li>
                <li>
                  2.4. <strong>Change of Plan.</strong> You may elect to change
                  your Plan at any time in accordance with the following terms.
                  Plan upgrades may be handled automatically by you through the
                  SaaS Service settings and will take effect immediately upon
                  change. Plan downgrades require written notice from you and
                  must be implemented by us and will take effect within 3
                  business days of our receipt and acknowledgement of your
                  notice. Upon a change of Plan, (i) the Subscription Term
                  associated with the old Plan will terminate immediately and be
                  replaced with a new Subscription Term equal to the original
                  Subscription Term; (ii) you will be refunded a prorated amount
                  of any Subscription Fees paid under the old Plan based on the
                  remainder of the old Subscription Term; and (iii) we will
                  charge you, and you will pay, the Subscription Fees associated
                  with the new Plan for the new Subscription Term.
                </li>
              </ul>

              <strong>3. CONFIDENTIALITY AND DATA SECURITY.</strong>

              <ul>
                <li>
                  3.1. <strong>Confidential Information.</strong> Each party
                  (“Recipient”) shall hold in confidence all information
                  received from the other party (“Discloser”), including any
                  business and technical information, which is marked or
                  otherwise designated as proprietary or confidential or which
                  the receiving party knows or should know is confidential or
                  proprietary under the circumstances (“Confidential
                  Information”). Recipient shall protect Discloser’s
                  Confidential Information in the same manner that Recipient
                  protects its own Confidential Information of similar
                  importance, but in no event with less than reasonable care.
                  Recipient shall only use the Confidential Information of the
                  Discloser for the purposes permitted under this Agreement and
                  shall not disclose such Confidential Information to any third
                  parties except to its employees and contractors who need to
                  know such information and are subject to binding use and
                  disclosure restrictions at least as protective as those set
                  forth herein. Notwithstanding the foregoing, Confidential
                  Information shall not include any information that: (i) is or
                  becomes publicly known without the Recipient’s breach of any
                  obligations owed to the Discloser; (ii) is rightfully
                  disclosed to the Recipient from a source other than the
                  Discloser without a breach of an obligation of
                  confidentiality; (iii) is independently developed by the
                  Recipient without any use of or reliance on the Discloser’s
                  Confidential Information; or (iv) is required to be disclosed
                  by applicable law or court order, provided that the Recipient
                  provides the Discloser with reasonable advance notice of such
                  disclosure and assistance to the Discloser to contest such
                  order or requirement and prevent or limit such disclosure.
                </li>
                <li>
                  3.2. <strong>Data Protection and Security.</strong> All data,
                  information, or material provided or submitted by you to us
                  through the SaaS Service (“Content”) will be considered your
                  Confidential Information (except as set forth in Section 4.1
                  above) and will also be protected and used pursuant to our
                  then-current privacy policy found at
                  https://app.realsynch.com/privacy, which is incorporated by
                  reference into this Agreement. You are responsible for
                  ensuring that the security of the SaaS Service is appropriate
                  for your intended use and the storage, hosting, or processing
                  of any Content. You are responsible for taking and maintaining
                  appropriate steps to protect the confidentiality, integrity,
                  and security of all Content from unauthorized access, use,
                  loss, or destruction. Those steps include: (a) controlling
                  access you provide to the SaaS Service; (b) configuring the
                  SaaS Service appropriately; (c) ensuring the security of
                  Content while it is in transit to and from the SaaS Service;
                  (d) using encryption technology to protect Content; and (e)
                  backing up Content. You represent and warrant that: (i) you or
                  your licensors own all right, title and interest in and to
                  Content or have all the rights in the Content to use it and
                  grant the rights contemplated in this Agreement and (ii) no
                  Content will contain any harmful or malicious code, files,
                  scripts, agents or programs.
                </li>
                <li>
                  3.3. <strong>Usage Data.</strong> We may monitor and collect
                  performance and usage data associated with your use of the
                  SaaS Service (“Usage Data”) and use the Usage Data: (a) to
                  facilitate delivery of the SaaS Service (such as tracking
                  Licensed Units, providing support, monitoring the performance
                  of the SaaS Service, and preventing or addressing service or
                  technical issues) and (b) to generally improve our products
                  and services. We may disclose usage Data in aggregate form
                  (e.g., data aggregated from your and other customers’ use of
                  the SaaS Service, but does not identify you or any other
                  customer) for promotion, statistical analysis, market
                  analysis, financial analysis, and other such purposes.
                </li>
              </ul>

              <strong>4. PROPRIETARY RIGHTS.</strong>

              <ul>
                <li>
                  4.1. <strong>Ownership of SaaS Service.</strong> As between us
                  and you, we or our licensors own all worldwide right, title,
                  and interest in and to the SaaS Service, including all
                  worldwide intellectual property rights therein. We reserve all
                  rights and licenses in and to the SaaS Service not expressly
                  granted to you under this Agreement.
                </li>
                <li>
                  4.2. <strong>Ownership of Content. </strong>As between you and
                  us, you or your licensors own all worldwide right, title and
                  interest in and to the Content, including all worldwide
                  intellectual property rights therein. You hereby grant to us,
                  and we hereby accept, a non-exclusive license to use, copy,
                  store, modify, transmit, and display Content solely to the
                  extent necessary to provide the SaaS Service to you.
                </li>
                <li>
                  4.3. <strong>Feedback.</strong> You may from time to time
                  provide suggestions, comments, or other feedback to us with
                  respect to the improvement, correction, or modification of the
                  SaaS Service (collectively, “Feedback”). You agree that we
                  shall be free to use, disclose, reproduce, license,
                  distribute, and otherwise commercially exploit the Feedback
                  provided to us with respect to the SaaS Service as we sees
                  fit, entirely without obligation or restriction of any kind.
                </li>
                <li>
                  4.4. <strong>Third Party Licensors.</strong> You acknowledge
                  and agree that third party licensors may own certain
                  proprietary information and intellectual property rights
                  included in the SaaS Service. Such third party licensors are
                  third party beneficiaries entitled to enforce our rights and
                  your obligations hereunder and to seek appropriate legal and
                  equitable remedies, including but not limited to, damages and
                  injunctive relief, for your breach of such obligations. Except
                  as provided in this Section, there are no other third-party
                  beneficiaries to this Agreement.
                </li>
              </ul>

              <strong>5. PROFESSIONAL SERVICES.</strong>

              <ul>
                <li>
                  5.1. <strong>Professional Services Generally.</strong>
                   During the term of this Agreement, we may provide certain
                  professional services to you in connection with your use of
                  the SaaS Service (“Professional Services”). All such
                  Professional Services will be described in statements of work
                  mutually agreed upon by you and us and attached to this
                  Agreement (“Statement(s) of Work”). Unless otherwise provided
                  for in a Statement of Work, (i) we will invoice you on a
                  monthly basis for the Professional Services rendered plus all
                  expenses incurred during the previous month or as otherwise
                  specified in the invoice and (ii) you will pay such invoices
                  within thirty (30) days after the date of the invoice. The
                  parties' respective project contacts will be designated in the
                  Statement of Work ("Project Contacts"). The Project Contacts
                  are responsible for signature on all authorization and
                  approval forms, for facilitating communication between you and
                  us regarding all technical and business matters, and for
                  coordinating the scheduling, development, and testing of Work
                  Product (as defined below).
                </li>
                <li>
                  5.2. <strong>Cooperation.</strong> You acknowledge that the
                  timely provision of and access to cooperation, complete and
                  accurate information and data are essential to the performance
                  of any Professional Services under this Agreement and that our
                  obligation to complete any Professional Services is dependent
                  upon same. If the relevant requirement(s), project plan(s),
                  schedule, scope, specification(s), design(s), software,
                  hardware product(s), or related system environment(s) or
                  architecture are changed by you or any other person, we will
                  not be responsible for the change unless you and us
                  specifically consent to the change, scheduling, and additional
                  charges, if any, in writing.
                </li>
                <li>
                  5.3. <strong>Changes.</strong> If, at any time, you desire to
                  modify an applicable Statement of Work, you will present a
                  written request to us describing such modifications using our
                  standard project change request form (each such request is a
                  "Change Order"). We will promptly review each such Change
                  Order and determine and advise you, in our reasonable
                  discretion, whether such Change Order can be accomplished by
                  us, and whether the performance of such Change Order will
                  increase the costs and/or delay the original schedule for
                  performing the Professional Services. If we approves such
                  Change Order (including without limitation any such increased
                  costs and/or delays), the Change Order, upon execution by the
                  parties, will be deemed to amend and become part of the
                  applicable Statement of Work and we will perform the
                  Professional Services in accordance with such amended
                  Statement of Work.
                </li>
                <li>
                  5.4. <strong>Work Product.</strong> As used in this Agreement,
                  “Work Product” means any deliverables created by us under
                  Statements of Work. Work Product does not include the SaaS
                  Service or your Resources (as defined below). Subject to the
                  terms and conditions of this Agreement, we hereby grants to
                  you a nonexclusive, nontransferable, revocable, limited
                  license during the Term to use the Work Product solely in
                  connection with your use of the SaaS Service. We shall own all
                  right, title and interest in and to any Work Product and all
                  intellectual property rights associated therewith. You hereby
                  assign and agree to assign all right, title and interest in
                  and to the Work Product and any intellectual property
                  associated therewith to us and fully cooperate with us in
                  perfecting such rights at our expense. Your rights in the Work
                  Product are limited to those expressly granted in this
                  Section. We reserve all rights and licenses to Work Product
                  not expressly and unambiguously granted to you in this
                  Agreement. You shall retain ownership of all right, title, and
                  interest in and to your Resources and intellectual property
                  rights embodied therein. You have or will secure all necessary
                  third-party consents, permissions, clearances, authorizations
                  and waivers required for the use of your Resources by us. As
                  used herein, “Resources” means any pre-existing or preparatory
                  materials, including, without limitation, data, content,
                  software, protocols, interfaces, templates, specifications,
                  related documentation, and other materials and resources, that
                  are provided to us by you to facilitate the provision of
                  Professional Services by us hereunder. You hereby grant to us
                  a non-exclusive and non-transferable license to use, modify,
                  reproduce, and create derivative works of your Resources
                  solely for use in connection with the Professional Services
                  being provided to you hereunder.
                </li>
                <li>
                  5.5. <strong>General Skill and Knowledge.</strong>
                   Notwithstanding anything to the contrary in this Agreement,
                  we will not be prohibited or enjoined at any time by you from
                  utilizing any "skills or knowledge of a general nature"
                  acquired during the course of performing Professional
                  Services. For purposes of this Agreement, "skills or knowledge
                  of a general nature" will include, without limitation,
                  information publicly known or that could reasonably have been
                  acquired in the conduct of similar work performed for another
                  customer.
                </li>
              </ul>

              <strong>6. WARRANTIES AND DISCLAIMER.</strong>

              <ul>
                <li>
                  6.1. <strong>SaaS Service Warranty.</strong> We warrant that
                  during the Term the SaaS Service will perform substantially in
                  accordance with the Documentation. For any breach of the
                  foregoing warranty, as your exclusive remedy and our entire
                  liability and at our sole option and expense, we will correct
                  the nonconforming SaaS Service, or if we are unable to correct
                  the nonconforming SaaS Service, we will refund to you the
                  Subscription Fees paid by you under the applicable Order Form
                  for the nonconforming SaaS Service prorated based on the
                  remainder of the then-current Subscription Term. For the
                  avoidance of doubt, in connection with the warranty remedy set
                  forth in this Section, we shall have no obligation to refund
                  any fees paid by you for Professional Services. In order to
                  receive the foregoing warranty remedies, you must make any
                  claim under the foregoing warranty to us in writing within the
                  30-days of the alleged breach. In the event of a refund under
                  this Section, this Agreement and your right to use the SaaS
                  Service will immediately terminate. The foregoing warranty
                  does not extend to any claim that arises from, relates to, or
                  is attributable to (a) your Content or hardware or software
                  supplied by you or a third-party or (b) use of the SaaS
                  Service other than in accordance with the terms and conditions
                  of this Agreement and the Documentation.
                </li>
                <li>
                  6.2. <strong>Professional Services Warranty.</strong> We
                  warrant that the Professional Services will be performed in a
                  professional and workmanlike manner in accordance with
                  generally accepted industry standards. For any breach of the
                  foregoing warranty, as your exclusive remedy and our entire
                  liability, we will re-perform the applicable Professional
                  Services, or if we are unable to re-perform the Professional
                  Services as warranted, we will refund the Professional
                  Services fees paid by you to us for the deficient Professional
                  Services. In order to receive the foregoing warranty remedies,
                  you must make any claim under the foregoing warranty to us in
                  writing within 30 days of performance of such Professional
                  Services.
                </li>
                <li>
                  6.3. <strong>Disclaimer. </strong>EXCEPT AS EXPRESSLY PROVIDED
                  ABOVE IN THIS SECTION 6, THE SAAS SERVICE AND PROFESSIONAL
                  SERVICES ARE PROVIDED “AS IS” AND WITH ALL FAULTS. WE HEREBY
                  DISCLAIM ALL REPRESENTATIONS, WARRANTIES, AND CONDITIONS,
                  WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT
                  LIMITED TO, IMPLIED WARRANTIES OF NON-INFRINGEMENT,
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE (EVEN IF
                  WE HAVE BEEN INFORMED OF SUCH PURPOSE), OF RELIABILITY OR
                  AVAILABILITY, OR ANY WARRANTIES ARISING FROM COURSE OF DEALING
                  OR USAGE OF TRADE WITH REGARD TO THIS AGREEMENT. WE DO NOT
                  WARRANT THAT THE SAAS SERVICE OR PROFESSIONAL SERVICES WILL BE
                  PROVIDED ERROR FREE, WILL OPERATE WITHOUT INTERRUPTION, OR
                  THAT ANY SAAS SERVICE OR PROFESSIONAL SERVICES WILL FULFILL
                  YOUR REQUIREMENTS. TO THE EXTENT THAT WE CANNOT DISCLAIM ANY
                  SUCH WARRANTIES AS A MATTER OF APPLICABLE LAW, THE SCOPE AND
                  DURATION OF SUCH WARRANTIES WILL BE THE MINIMUM REQUIRED UNDER
                  SUCH LAW. The above disclaimers extend to us and our
                  affiliates, suppliers, and licensors, and our and their
                  shareholders, officers, directors, employees, representatives,
                  and agents (the “Real Synch Parties”).
                </li>
              </ul>

              <strong>7. TERM, TERMINATION, AND SUSPENSION.</strong>

              <ul>
                <li>
                  7.1. <strong>Subscription Terms and Renewals.</strong> The
                  initial Subscription Term shall commence on the date that we
                  provide you with an authentication key that enables you to
                  access the SaaS Service. The Subscription Term will
                  automatically renew for an additional Subscription Term equal
                  to the expiring Subscription Term, unless either party gives
                  the other written notice of non-renewal at least 30 days
                  before the end of the relevant Subscription Term. The SaaS
                  Service pricing during any such renewal Subscription Term
                  shall be the same as that during the prior term unless we have
                  given you written notice of a pricing change at least 30 days
                  before the end of such prior term, in which case the pricing
                  change shall be effective upon renewal and thereafter.
                </li>
                <li>
                  7.2. <strong>Termination for Cause.</strong> Either party may
                  terminate this Agreement: (i) if the other party is in
                  material breach of this Agreement and fails to cure the breach
                  within 15 days of receiving written notice from the
                  non-breaching party or (ii) if the other party becomes the
                  subject of a petition in bankruptcy or any proceeding relating
                  to insolvency, receivership, liquidation or assignment for the
                  benefit of creditors.
                </li>
                <li>
                  7.3. <strong>Termination for Convenience.</strong> You may
                  also terminate this Agreement for convenience at any time upon
                  30 days advance written notice. Upon such notice, we will
                  refund to you a prorated amount of the Subscription Fees paid
                  by you based on the remainder of the then-current Subscription
                  Term measured from the effective date of termination. We may
                  charge a reasonable termination fee for termination by you
                  under this Section. We may subtract the termination fee from
                  the amount of the refund, if any, owed to you under this
                  Section.
                </li>
                <li>
                  7.4. <strong>Effect of Termination.</strong> Upon any
                  termination or expiration of this Agreement: (i) all licenses
                  and rights granted to you hereunder shall terminate and we
                  will no longer provide any SaaS Service or Professional
                  Services to you, (ii) you shall cease using the SaaS Service;
                  (iii) you shall promptly return, or if we request, destroy any
                  of our Confidential Information in your possession and
                  control; and (iv) within 7 days of expiration or earlier
                  termination of this Agreement, you will pay to us all fees
                  outstanding. In addition to any provisions that by their terms
                  survive the termination or expiration of this Agreement, the
                  following provisions will also survive: Sections 1.3, 2.3, 3,
                  4, 5.5, 6.3, 7.4, 8, 9, and 10.
                </li>
                <li>
                  7.5. <strong>Suspension.</strong> We may suspend your use of
                  the SaaS Service if: (i) you are in breach of this Agreement
                  and do not cure that breach within 15 days after we notify you
                  of that breach; (ii) your use of the SaaS Service poses a
                  security risk to the SaaS Service or to other users of the
                  SaaS Service; or (iii) suspension is required pursuant to a
                  subpoena, court order, or other legal requirement. We will
                  give you notice before suspending your use of the SaaS Service
                  if permitted by applicable law or unless we reasonably
                  determine that providing notice presents a risk of harm to the
                  SaaS Service or other users of the SaaS Service, in which case
                  we will notify you as soon as feasible or permitted.
                </li>
                <li>
                  8. <strong>INDEMNIFICATION.</strong> You will defend,
                  indemnify and hold us harmless from and against any loss,
                  damage, liability or cost (including reasonable attorneys’
                  fees) resulting from any third party claim based on: (i) your
                  use of Content that infringes the rights of, or has caused
                  harm to, a third party; (ii) a violation by you of your
                  representations and warranties; or (iii) a breach by you of
                  this Agreement; provided that we promptly notify you in
                  writing of any and all such claims. In the event of any loss,
                  damage, liability or cost for which you are obligated to
                  indemnify us hereunder, you shall have sole control of the
                  defense and all related settlement negotiations, and we will
                  reasonably cooperate with you in the defense and/or settlement
                  thereof at your expense; provided that we may participate in
                  such defense using its own counsel, at its own expense.
                </li>
              </ul>

              <strong>9. LIMITATION OF LIABILITY.</strong>

              <ul>
                <li>
                  9.1. <strong>Exclusion of Consequential Damages.</strong>
                   TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE
                  REAL SYNCH PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                  DAMAGES FOR LOSS OF PROFITS OR REVENUES, BUSINESS
                  INTERRUPTION, LOSS OF DATA, LOSS OF BUSINESS INFORMATION
                  (WHETHER ANY OF THE FOREGOING ARE INCURRED DIRECTLY OR
                  INDIRECTLY) OR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE,
                  CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OR RELATED TO
                  THIS AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT
                  LIMITATION, NEGLIGENCE), OR OTHER LEGAL OR EQUITABLE THEORY,
                  EVEN IF A PARTY HAS BEEN ADVISED OF, KNOWS OF, OR SHOULD HAVE
                  KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
                </li>
                <li>
                  9.2. <strong>Limitation of Liability.</strong> IN NO EVENT
                  SHALL THE REAL SYNCH PARTIES’AGGREGATE LIABILITY ARISING OUT
                  OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT
                  (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHER LEGAL OR
                  EQUITABLE THEORY, EXCEED THE TOTAL AMOUNTS PAID BY YOU TO US
                  FOR THE UNDER THIS AGREEMENT IN THE TWELVE (12) MONTH PERIOD
                  PRECEDING THE EVENT GIVING RISE TO THE LIABILITY.
                </li>
              </ul>

              <strong>10. GENERAL.</strong>
              <ul>
                <li>
                  10.1. <strong>Notices.</strong> Notices under this Agreement
                  must be in writing and will be considered effective on the
                  earlier of actual receipt or: (i) the first day after delivery
                  by registered mail (or by courier with tracking number) or
                  (ii) the second business day after sending by email. Billing
                  notices and other general notices relating to a party’s
                  performance under this Agreement may be sent via email to the
                  contacts specified by the other party. All legal notices under
                  this Agreement must be delivered by registered mail (or by
                  courier with tracking number) to the attention of the
                  receiving party’s legal department.
                </li>
                <li>
                  10.2. <strong>Governing Law and Dispute Resolution.</strong>
                   The parties shall use good faith, reasonable efforts to
                  resolve any dispute before initiating legal action. This
                  Agreement shall be governed by the laws of the State of Texas
                  and the United States, without reference to conflict of laws
                  principles. The parties expressly disclaim the application of
                  the United Nations Convention on the International Sale of
                  Goods to this Agreement and the Uniform Computer Information
                  Transactions Act as it may be enacted in the applicable
                  jurisdiction. Exclusive jurisdiction and venue for all
                  disputes arising under this Agreement will be in the state and
                  federal courts residing in Austin, Texas. Each party expressly
                  consents to the exercise of jurisdiction over it in the above
                  venue of any court of competent jurisdiction and waive any
                  rights it may have to have the action tried or determined in a
                  different venue.
                </li>
                <li>
                  10.3. <strong>Entire Agreement.</strong> This Agreement
                  constitutes the complete and exclusive understanding and
                  agreement between the parties regarding their subject matter
                  and supersedes all prior or contemporaneous agreements or
                  understandings, written or oral, relating to their subject
                  matter. Any additional or different terms in your documents
                  (including any terms contained on ordering documents and
                  purchase orders) shall not apply and are hereby deemed to be
                  material alterations and notice of objection to, and rejection
                  of them, is hereby given.
                </li>
                <li>
                  10.4. <strong>Amendments.</strong> We may modify the terms of
                  this Agreement or SaaS Service at any time. You will be given
                  notice of any amendments to this Agreement or the SaaS
                  Service. We may provide notice to you by means of (i) posting
                  information or a link to it in the SaaS Service or on our
                  website or (ii) by email to your e-mail address on record
                  under your user account. Such notice shall be deemed to have
                  been given upon the expiration of three days after posting or
                  one day after emailing.
                </li>
                <li>
                  10.5. <strong>Assignment.</strong> Neither party may assign or
                  transfer this Agreement, in whole or in part, without the
                  other party’s prior written consent (not to be unreasonably
                  withheld. Any attempt to assign or transfer this Agreement
                  without the prior written consent of the other party shall be
                  void. Notwithstanding the foregoing, either party may assign
                  this Agreement in its entirety without approval of the other
                  party to a party that succeeds to all or substantially all of
                  its assets (whether by sale, merger, operation of law or
                  otherwise), so long as such assignee or transferee agrees in
                  writing to be bound by the terms and conditions of this
                  Agreement. This Agreement shall be binding upon and shall
                  inure to the benefit of the parties hereto and their
                  authorized successors and permitted assigns.
                </li>
                <li>
                  10.6. <strong>Marketing.</strong> During the Term, we may
                  include your name, logo, and success stories (if provided) on
                  our website and in any press releases, promotional and sales
                  literature, and lists of customers.
                </li>
                <li>
                  10.7. <strong>Force Majeure.</strong> Except with respect to
                  the obligation to pay fees when due hereunder, neither party
                  shall be deemed in default of this Agreement because of a
                  delay or failure in performance of its obligation resulting
                  from any cause beyond its reasonable control (a "Force
                  Majeure"), provided it gives reasonably prompt notice of the
                  Force Majeure condition to the other party and uses reasonable
                  efforts to mitigate the delay or failure.
                </li>
                <li>
                  10.8. <strong>Basis of Bargain.</strong> Each party recognizes
                  and agrees that the warranty disclaimers and liability and
                  remedy limitations in this Agreement are material bargained
                  for bases of this Agreement and that they have been taken into
                  account and reflected in determining the consideration to be
                  given by each party under this Agreement and in the decision
                  by each party to enter into this Agreement. The parties agree
                  that the limitations and exclusions of liability and
                  disclaimers specified in this Agreement will survive and apply
                  even if found to have failed of their essential purpose.
                </li>
                <li>
                  10.9. <strong>Construction.</strong> The headings of sections
                  of this Agreement are for convenience and are not for use in
                  interpreting this. As used in this Agreement, the word
                  “including” means “including but not limited to.”
                </li>
                <li>
                  10.10. <strong>Waiver.</strong> No waiver of any provision of
                  this Agreement will be effective unless it is in writing and
                  signed by duly authorized representative of the party against
                  whom the waiver is to be asserted. The failure by either party
                  to enforce any provision of this Agreement will not constitute
                  a waiver of future enforcement of that or any other provision.
                </li>
                <li>
                  10.11. <strong>Severability.</strong> If any provision of this
                  Agreement is held by a court of competent jurisdiction to be
                  invalid or unenforceable, then such provision(s) will be
                  construed, as nearly as possible, to reflect the intentions of
                  the invalid or unenforceable provision(s), with all other
                  provisions remaining in full force and effect.
                </li>
                <li>
                  10.12. <strong>Independent Contractors. </strong>The parties
                  to this Agreement are independent contractors and this
                  Agreement will not establish any relationship of partnership,
                  joint venture, employment, franchise, or agency between the
                  parties.
                </li>
              </ul>
            </div>
          </StyledContent>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
